import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';

import { parseImages, getEmMarkup } from 'src/helpers/parseMarkdown';
import { getAltText } from 'src/helpers/getAltText';
import svgDataURL from 'src/helpers/svgDataURL';

import {
  Assets,
  Cta,
  Club,
  Hero,
  Passion,
  Quotes,
  QuotesHeading,
  ResearchHeading,
  ResearchList,
  Resource,
} from './AskHagerty.types';

const parseHero = (markdown: string): Hero => {
  const tokens = marked.lexer(markdown);
  const hero = {
    title: null,
    subtitle: null,
    images: [],
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        hero.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          hero.images = parseImages(markdown);
        } else if (token.tokens[0].type === 'text') {
          hero.subtitle = getEmMarkup(token.raw);
        }
        break;
      }
    }
  }

  return hero;
};

const parsePassion = (markdown: string): Passion => {
  const tokens = marked.lexer(markdown);
  const passion = {
    title: null,
    subtitle: null,
    list: [],
    image: null,
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        passion.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          passion.image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        } else if (token.tokens[0].type === 'text' || token.tokens[0].type === 'em') {
          passion.subtitle = getEmMarkup(token.raw);
        }
        break;
      }
    }
  }

  return passion;
};

const parseQuotes = (markdown: string): Quotes => {
  const tokens = marked.lexer(markdown);
  const quotes = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        quotes.push({
          quote: token.text,
          member: null,
        });
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'text') {
          quotes[quotes.length - 1].member = token.text;
        }
        break;
      }
    }
  }

  return quotes;
};

const parseQuotesHeading = (markdown: string): QuotesHeading => {
  const tokens = marked.lexer(markdown);
  const heading = {
    overline: null,
    title: null,
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'paragraph': {
        heading.overline = token.text;
        break;
      }
      case 'heading': {
        heading.title = token.text;
        break;
      }
    }
  }

  return heading;
};

const parseClub = (markdown: string): Club => {
  const tokens = marked.lexer(markdown);
  const club = {
    title: null,
    subtitle: null,
    list: [],
    image: null,
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        club.title = token.text;
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          club.image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        } else if (token.tokens[0].type === 'text' || token.tokens[0].type === 'em') {
          club.subtitle = token.text;
        }
        break;
      }
      case 'list': {
        club.list = token.items.map((item) => getEmMarkup(item.text));
        break;
      }
    }
  }

  return club;
};

const parseCta = (markdown: string): Cta => {
  const tokens = marked.lexer(markdown);
  const cta = {
    overline: null,
    title: null,
    image: null,
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          cta.image = {
            alt: getAltText(token.tokens[0].text),
            url: (token.tokens[0] as marked.Tokens.Image).href,
          };
        } else if (token.tokens[0].type === 'text') {
          cta.overline = token.text;
        }
        break;
      }
      case 'heading': {
        cta.title = token.text;
        break;
      }
    }
  }

  return cta;
};

const parseResearchHeading = (markdown: string): ResearchHeading => {
  const tokens = marked.lexer(markdown);
  const heading = {
    overline: null,
    title: null,
    images: [],
  };

  for (const token of tokens) {
    switch (token.type) {
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          heading.images = parseImages(markdown);
        } else if (token.tokens[0].type === 'text') {
          heading.overline = token.text;
        }
        break;
      }
      case 'heading': {
        heading.title = token.text;
        break;
      }
    }
  }

  return heading;
};

const parseResearchList = (markdown: string): ResearchList => {
  const tokens = marked.lexer(markdown);
  const list = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        list.push({
          title: token.text,
          subtitle: null,
          svg: null,
        });
        break;
      }
      case 'paragraph': {
        if (token.text.startsWith('<svg')) {
          list[list.length - 1].svg = svgDataURL(token.text);
        }
        list[list.length - 1].subtitle = token.text;
        break;
      }
    }
  }

  return list;
};

const useAskHagertyAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo<Assets>((): Assets => {
    return {
      cta: parseCta(t(Resource.Cta)),
      club: parseClub(t(Resource.Club)),
      hero: parseHero(t(Resource.Hero)),
      passion: parsePassion(t(Resource.Passion)),
      quotes: parseQuotes(t(Resource.Quotes)),
      quotesHeading: parseQuotesHeading(t(Resource.QuotesHeading)),
      researchHeading: parseResearchHeading(t(Resource.ResearchHeading)),
      researchList: parseResearchList(t(Resource.ResearchList)),
    };
  }, [ready]);
};

export {
  parseHero,
  parsePassion,
  parseQuotes,
  parseQuotesHeading,
  parseClub,
  parseCta,
  parseResearchHeading,
  parseResearchList,
  useAskHagertyAssets,
};
