import React from 'react';

import styles from './AskHagertyResearch.module.scss';

import { useAskHagertyAssets } from './useAskHagertyAssets';
import { useResponsiveImage } from 'src/hooks/useResponsiveImage';
import { CopyImageColumns } from 'src/components/CopyMediaColumns';

const AskHagertyResearch: React.FC = () => {
  const assets = useAskHagertyAssets();
  const { researchHeading, researchList } = assets;

  const imageUrl = useResponsiveImage({
    mobile: researchHeading.images[0].url,
    tablet: researchHeading.images[1].url,
    desktop: researchHeading.images[2].url,
  });

  const list = (
    <ul className={styles.askHagertyResearch__list}>
      {researchList.map((item) => (
        <li
          key={`research-list-${item.title}`}
          className={`${styles.askHagertyResearch__item} no-padding`}
          data-cy={`ask-hagerty-research-item-${item.title}`}
        >
          <img
            src={item.svg}
            alt="icon"
            className={`${styles.askHagertyResearch__itemImage} svg`}
            data-cy="ask-hagerty-research-item-image"
          />
          <div>
            <p
              className={`${styles.askHagertyResearch__itemTitle} no-padding`}
              data-cy="ask-hagerty-research-item-title"
            >
              {item.title}
            </p>
            <p
              className={`${styles.askHagertyResearch__itemSubtitle} no-padding`}
              data-cy="ask-hagerty-research-item-subtitle"
            >
              {item.subtitle}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <section className={styles.askHagertyResearch} data-cy="ask-hagerty-research">
      <div className={`${styles.askHagertyResearch__container} container`}>
        <CopyImageColumns
          className={styles.askHagertyResearch__copyImage}
          title={researchHeading.title}
          overline={researchHeading.overline}
          imageAlt={researchHeading.images[0].alt}
          imageUrl={imageUrl}
          reverseOrder
          copyChildren={list}
          copyContainerClassName={styles.askHagertyResearch__copyContainer}
        />
      </div>
    </section>
  );
};

export { AskHagertyResearch };
