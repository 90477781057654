import React from 'react';

import styles from './AskHagertyCta.module.scss';

import { useAskHagertyAssets } from './useAskHagertyAssets';
import { useMediaMobile } from 'src/hooks/useMediaMobile';
import { CopyImageColumns, Variant } from 'src/components/CopyMediaColumns';
import { JoinCta } from 'src/components/JoinCta';

const AskHagertyCta: React.FC = () => {
  const assets = useAskHagertyAssets();
  const { cta } = assets;
  const isMobile = useMediaMobile();

  const imageUrl = isMobile ? null : cta.image.url;

  return (
    <section className={`${styles.askHagertyCta} color-background-blue-gradient`} data-cy="ask-hagerty-cta">
      <div className="container">
        <CopyImageColumns
          className={styles.askHagertyCta__copyImage}
          title={cta.title}
          overline={cta.overline}
          imageAlt={cta.image.alt}
          imageUrl={imageUrl}
          isXLargeTitle
          variant={Variant.Light}
          reverseOrder
          copyChildren={
            <JoinCta
              className="button__gradient"
              disclaimerClassName={`${styles.askHagertyCta__disclaimer} color-white disclaimer-light-link`}
              testid="ask-hagerty-hero-link"
            />
          }
        />
      </div>
    </section>
  );
};

export { AskHagertyCta };
