import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useTreatments } from '@splitsoftware/splitio-react';

import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import { useAutoLogin } from 'src/hooks/useAutoLogin';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { useTracking } from 'src/components/TrackingContext/useTracking';
import useMembership from 'src/api/useMembership';
import {
  AskHagertyHero,
  AskHagertyPassion,
  AskHagertyQuotes,
  AskHagertyClub,
  AskHagertyCta,
  AskHagertyResearch,
} from 'src/features/AskHagerty';
import { SiteDisclaimer } from 'src/components/Disclaimers';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { formatTestExperience } from 'src/helpers/formatTestExperience';
import { MainFooter } from 'src/features/footer';

const Magazine: React.FC<PageProps> = () => {
  usePreventAgentAccess();
  useAutoLogin();
  const [experiments, setExperiments] = useState([]);
  const { t } = useTranslation();
  const { isEligibleForFreeTrial, freeTrialIsReady } = useFreeTrial();
  const { data: membership } = useMembership();

  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureAskHagerty]);
  const hasAskHagertyFeature = treatments[Split.FeatureAskHagerty].treatment === Treatment.On;

  useEffect(() => {
    if (freeTrialIsReady) {
      const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
      setExperiments([freeTrialExperiment]);
    }
  }, [freeTrialIsReady]);

  useEffect(() => {
    if (membership?.hasMembership() && splitIsReady && hasAskHagertyFeature) {
      window.location.replace(`${process.env.HAGERTY_ROOT_URL}/drivers-club/member/ask-hagerty/`);
    }
  }, [membership, splitIsReady]);

  useTracking('ask_hagerty_view', experiments);

  return (
    <>
      <Helmet>
        <title>{t('head.title.ask-hagerty')}</title>
        <meta name="description" content={t('head.meta-description.ask-hagerty')} />
        <meta name="robots" content="all" />
        <body className="page__ask-hagerty page-container-padding" />
      </Helmet>
      <AskHagertyHero />
      <AskHagertyResearch />
      <AskHagertyPassion />
      <AskHagertyQuotes />
      <AskHagertyClub />
      <AskHagertyCta />
      <footer className="container">
        <SiteDisclaimer />
      </footer>

      <MainFooter />
    </>
  );
};

export default Magazine;
