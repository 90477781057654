type Image = {
  alt: string;
  url: string;
};

type Cta = {
  overline: string;
  title: string;
  image: Image;
};

type Club = {
  title: string;
  subtitle: string;
  list: string[];
  image: Image;
};

type Hero = {
  title: string;
  subtitle: string;
  images: Image[];
};

type Passion = {
  title: string;
  subtitle: string;
  image: Image;
};

type Quote = {
  quote: string;
  member: string;
};

type Quotes = Quote[];

type QuotesHeading = {
  overline: string;
  title: string;
};

type ResearchHeading = {
  overline: string;
  title: string;
  images: Image[];
};

type ResearchItem = {
  title: string;
  subtitle: string;
  svg: string;
};

type ResearchList = ResearchItem[];

enum Resource {
  Cta = 'ask-hagerty.cta',
  Club = 'ask-hagerty.club',
  Hero = 'ask-hagerty.hero',
  Passion = 'ask-hagerty.passion',
  Quotes = 'ask-hagerty.quotes',
  QuotesHeading = 'ask-hagerty.quotes.header',
  ResearchHeading = 'ask-hagerty.research.heading',
  ResearchList = 'ask-hagerty.research.list',
}

type Assets = {
  cta: Cta;
  club: Club;
  hero: Hero;
  passion: Passion;
  quotes: Quotes;
  quotesHeading: QuotesHeading;
  researchHeading: ResearchHeading;
  researchList: ResearchList;
};

export { Cta, Club, Hero, Passion, Quotes, QuotesHeading, ResearchHeading, ResearchList, Resource, Assets };
