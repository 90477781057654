import React from 'react';

import styles from './AskHagertyPassion.module.scss';

import { useAskHagertyAssets } from './useAskHagertyAssets';
import { CopyImageColumns, Variant } from 'src/components/CopyMediaColumns';

const AskHagertyPassion: React.FC = () => {
  const assets = useAskHagertyAssets();
  const { passion } = assets;

  return (
    <section data-cy="ask-hagerty-passion">
      <div className="container">
        <CopyImageColumns
          title={passion.title}
          paragraph={passion.subtitle}
          imageAlt={passion.image.alt}
          imageUrl={passion.image.url}
          variant={Variant.Dark}
          isLargerParagraph
          copyContainerClassName={styles.askHagertyPassion__copyContainer}
        />
      </div>
    </section>
  );
};

export { AskHagertyPassion };
