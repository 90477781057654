import React from 'react';

import styles from './AskHagertyQuotes.module.scss';

import { useAskHagertyAssets } from './useAskHagertyAssets';
import { QuotesSlider } from 'src/components/QuotesSlider/QuotesSlider';
import FadeIn from 'src/components/FadeIn';

const AskHagertyQuotes: React.FC = () => {
  const assets = useAskHagertyAssets();
  const { quotes, quotesHeading } = assets;

  return (
    <div className={`${styles.askHagertyQuotes} color-background-blue-gradient`} data-cy="ask-hagerty-quotes">
      <FadeIn duration={0.4} up>
        <div className={`${styles.askHagertyQuotes__header} text-align-center display-mq-large-none`}>
          <p className="color-white" data-cy="ask-hagerty-quotes-overline">
            {quotesHeading.overline}
          </p>
          <h2 className="font-size-title-x-large color-white" data-cy="ask-hagerty-quotes-title">
            {quotesHeading.title}
          </h2>
        </div>
      </FadeIn>
      <QuotesSlider className={styles.askHagertyQuotes__slider} quotes={quotes} />
    </div>
  );
};

export { AskHagertyQuotes };
