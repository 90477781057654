import React from 'react';

import styles from './AskHagertyClub.module.scss';

import { useAskHagertyAssets } from './useAskHagertyAssets';
import { useMediaMobile } from 'src/hooks/useMediaMobile';
import { CopyImageColumns } from 'src/components/CopyMediaColumns';
import { CheckList, Checkmark } from 'src/components/CheckList';

const AskHagertyClub: React.FC = () => {
  const assets = useAskHagertyAssets();
  const { club } = assets;
  const isMobile = useMediaMobile();

  const imageUrl = isMobile ? '' : club.image.url;

  return (
    <section className={styles.askHagertyClub} data-cy="ask-hagerty-club">
      {isMobile && (
        <img
          className={styles.askHagertyClub__mobileImage}
          src={club.image.url}
          alt={club.image.alt}
          data-cy="ask-hagerty-club-image"
        />
      )}
      <div className={`${styles.askHagertyClub__container} container`}>
        <CopyImageColumns
          title={club.title}
          paragraph={club.subtitle}
          imageAlt={club.image.alt}
          imageUrl={imageUrl}
          copyChildren={<CheckList items={club.list} checkmark={Checkmark.Blue} useHtml />}
        />
      </div>
    </section>
  );
};

export { AskHagertyClub };
